import { Injectable } from '@angular/core';

import { ContextService } from '../../services';

@Injectable()
export class FormDeactivateGuard  {

    constructor(
        private contextService: ContextService
    ) { }

    canDeactivate(): boolean {
        this.contextService.unsetElement();
        return true;
    }
}